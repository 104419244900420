import React from 'react';
import { graphql } from 'gatsby';
import {
  buildAnchors,
  jsonToJs,
  parseFields,
  remove
} from '../components/brief/data';

import BriefLayout from '../components/brief_layout';
import { buzzsproutStylesheet } from '../components/brief/podcast/player';
import Layout from '../components/layout';

import { RenderHead } from "../components/head"



//
// configuration

const samePath = (location, briefData) => {
  return location.pathname === briefData.slug_path
    || location.pathname === (briefData.slug_path + '/');
};



//
// head

export const Head = ({ data }) => {

  const brief = parseFields(data.thisBrief);
  const sanitizedUuid = brief.brief_id.replace(/-/g, '');
  const imagePath = '/brief/' + sanitizedUuid + '/social_card.jpg';

  const args = {
    title:       brief.headline,
    description: brief.social.description,
    imagePath,
    keywords:    brief.tags.all,
    scripts:     [buzzsproutStylesheet()]
  };

  return RenderHead(args);
};


//
// component

class BriefTemplate extends React.Component
{
  render()
  {
    const { data, location } = this.props;
    const briefs = jsonToJs(data.allBriefs);
    const brief = parseFields(data.thisBrief);
    const rest = remove(brief, briefs);
    const anchors = buildAnchors(brief);

    const includeJumpLink = (
      anchors && anchors.length > 0
    )
          || samePath(location, briefs[0]);  // always show for first brief

    const userFields = data.allUserFieldsJson.edges[0].node;

    return (
        <Layout
      location={ location }
      includeJumpLink={ includeJumpLink }
      anchors={ anchors } >

        <BriefLayout
      primary={ brief }
      rest={ rest }
      userFields={ userFields }
        />

      </Layout>
    );
  }
}


export default BriefTemplate;




export const pageQuery = graphql`
query briefByPath($path: String!)
{
  allBriefs: allInternalBriefsJson {
    edges {
      node {
        body_partial
        body_preview
        body_callouts
        body_quotes
        brief_id
        created_epoch_ts
        headline
        headline_pre
        published_epoch_ts
        published_date_str
        slug_path
        tags
        type
        word_count
      }
    }
  }

  thisBrief: internalBriefsJson (slug_path: { eq: $path }) 
  {
    body
    body_preview
    body_callouts
    body_partial
    body_quotes
    brief_id
    disclosure
    headline
    headline_pre
    podcast { id type }
    published_date_str
    published_epoch_ts
    slug_path
    social
    summary
    tags
    type
    word_count
  }

  allUserFieldsJson {
    edges {
      node {
        market_events
        market_sectors
        signals_selected
        shifts_researched
        quick_hits
      }
    }
  }
}
`;
